import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Combobox, useCombobox } from '@twilio-paste/combobox';
import { Button } from '@twilio-paste/button';
import { Table, THead, TBody, Td, Th, Tr } from '@twilio-paste/table';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { InformationIcon } from '@twilio-paste/icons/esm/InformationIcon';
import { LinkExternalIcon } from '@twilio-paste/icons/esm/LinkExternalIcon';
import { ProductStudioIcon } from '@twilio-paste/icons/esm/ProductStudioIcon';
import { ProductAutopilotIcon } from '@twilio-paste/icons/esm/ProductAutopilotIcon';
import { ProductInsightsIcon } from '@twilio-paste/icons/esm/ProductInsightsIcon';
import { SearchIcon } from '@twilio-paste/icons/esm/SearchIcon';
import { CloseIcon } from '@twilio-paste/icons/esm/CloseIcon';
import { Text } from '@twilio-paste/text';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SidebarCategoryRoutes } from '../../../constants';
import Changelog from '@twilio-paste/combobox/CHANGELOG.md';
import { autoCompleteExample, defaultExample, optionTemplateExample, errorExample, disabledExample, prefixSuffixExample, controlledComboboxExample, groupedComboboxExample, groupedLabelComboboxExample, stateHookCombobox } from '../../../component-examples/ComboboxExamples';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/combobox"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/combobox/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/combobox" storybookUrl="/?path=/story/components-combobox--default-combobox" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Using alpha components in your product</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Alpha components are ready for you to use in production. Components such as Combobox are likely to not see any API
    changes. This is only an alpha due to the lack of documentation and minimal design. Feel free to share your
    experience using this component at #help-design-system or{' '}
    <Anchor href="https://github.com/twilio-labs/paste/discussions/new" mdxType="Anchor">start a discussion on GitHub</Anchor>.
  </CalloutText>
        </Callout>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Combobox`}</h3>
        <p>{`The Combobox is an opinionated implementation and wrapper around the `}<strong parentName="p">{`very excellent `}<a parentName="strong" {...{
              "href": "https://downshift.netlify.app/downshift"
            }}>{`Downshift component`}</a></strong>{`.`}</p>
        <p>{`Combobox allows a user to make a selection from a styled list box of options. Each option can consist of more than just text,
`}<em parentName="p">{`e.g.`}</em>{` text paired with an icon. It can also be set up with autocomplete/typeahead functionality so users can easily find a
specific option.`}</p>
        <h2>{`Examples`}</h2>
        <h4>{`Basic Combobox`}</h4>
        <LivePreview scope={{
          Combobox
        }} noInline language="jsx" mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h4>{`Autocomplete Combobox`}</h4>
        <LivePreview scope={{
          Combobox
        }} noInline language="jsx" mdxType="LivePreview">
  {autoCompleteExample}
        </LivePreview>
        <h4>{`Combobox using Option Template`}</h4>
        <p>{`The `}<inlineCode parentName="p">{`optionTemplate`}</inlineCode>{` prop allows you to pass `}<inlineCode parentName="p">{`jsx`}</inlineCode>{` in order to display more complex items in the list box.`}</p>
        <LivePreview scope={{
          Combobox,
          LinkExternalIcon,
          MediaBody,
          MediaObject,
          MediaFigure,
          Text
        }} noInline language="jsx" mdxType="LivePreview">
  {optionTemplateExample}
        </LivePreview>
        <h4>{`Combobox with add-ons (prefix/suffix text or icons)`}</h4>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Prefix/suffix text`}</strong>{` - Text that can be used as a prefix or suffix to the value that is entered. Use prefix/suffix to help users format text.`}</li>
          <li parentName="ul"><strong parentName="li">{`Icon`}</strong>{` - Icons that can be placed in the same area as the prefix and suffix text. Icons should trigger an action (`}<em parentName="li">{`e.g.`}</em>{`, opening a popover).`}</li>
        </ul>
        <LivePreview scope={{
          Combobox,
          Anchor,
          InformationIcon
        }} noInline language="jsx" mdxType="LivePreview">
  {prefixSuffixExample}
        </LivePreview>
        <h4>{`Combobox with option groups`}</h4>
        <p>{`The list of options shown to the user, known as the `}<inlineCode parentName="p">{`Listbox`}</inlineCode>{`, can be grouped to create labelled sections. Structure your data into an array of objects and use a key on each object as the grouping identifier. Then tell the Combobox what you would like to group the items by, by setting `}<inlineCode parentName="p">{`groupItemsBy`}</inlineCode>{` to match the intended group identifier.`}</p>
        <p>{`In the example below we have a list of components and we are grouping them based on their type.`}</p>
        <LivePreview scope={{
          Combobox
        }} noInline language="jsx" mdxType="LivePreview">
  {groupedComboboxExample}
        </LivePreview>
        <h4>{`Combobox with custom group label`}</h4>
        <p>{`You can control the contents of the group label is a similar way to options, by providing a custom template. The `}<inlineCode parentName="p">{`groupLabelTemplate`}</inlineCode>{` prop takes a method with `}<inlineCode parentName="p">{`groupName`}</inlineCode>{` argument, that you can use to return valid JSX from. It will render the contents of that JSX as a child of the group label.`}</p>
        <p>{`In the example below we are checking the `}<inlineCode parentName="p">{`groupName`}</inlineCode>{` and rendering an icon next to it based on the name.`}</p>
        <LivePreview scope={{
          Combobox,
          ProductInsightsIcon,
          ProductAutopilotIcon,
          ProductStudioIcon,
          MediaObject,
          MediaFigure,
          MediaBody
        }} noInline language="jsx" mdxType="LivePreview">
  {groupedLabelComboboxExample}
        </LivePreview>
        <h4>{`Controlled Combobox`}</h4>
        <p>{`The Combobox can be used as a `}<a parentName="p" {...{
            "href": "https://reactjs.org/docs/forms.html#controlled-components"
          }}>{`controlled component`}</a>{` when you would like full control over your state. Use the properties `}<inlineCode parentName="p">{`selectedItem`}</inlineCode>{`, `}<inlineCode parentName="p">{`inputValue`}</inlineCode>{`, `}<inlineCode parentName="p">{`onInputValueChange`}</inlineCode>{` and `}<inlineCode parentName="p">{`onSelectedItemChange`}</inlineCode>{` to control the value of the Combobox via your own application state.`}</p>
        <p>{`In the example below the value of the Combobox is stored in a piece of our application state. We update that value based on user input into the Combobox, resetting the value of the Combobox. Upon the user selecting a defined option, we hook into `}<inlineCode parentName="p">{`onSelectedItemChange`}</inlineCode>{` to set our selectedItem state value based on user selection.`}</p>
        <LivePreview scope={{
          Combobox,
          Box,
          LinkExternalIcon,
          MediaBody,
          MediaObject,
          MediaFigure,
          Text
        }} noInline language="jsx" mdxType="LivePreview">
  {controlledComboboxExample}
        </LivePreview>
        <h4>{`useCombobox state hook`}</h4>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">🚨Power user move!🚨</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Only use this property if you are a power user. It's very easy to break your implementation and unfortunately the
    Paste team will not be able to debug this for you. Proceed with extreme caution.
  </CalloutText>
        </Callout>
        <p>{`In addition to being a controlled component, the Combobox comes with the option of "hooking" into the internal state by using the state hook originally provided by `}<a parentName="p" {...{
            "href": "https://github.com/downshift-js/downshift/tree/master/src/hooks/useCombobox"
          }}>{`Downshift`}</a>{`.`}</p>
        <p>{`Rather than the state be internal to the component, you can use the `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` hook and pass the returned state to `}<inlineCode parentName="p">{`Combobox`}</inlineCode>{` as the `}<inlineCode parentName="p">{`state`}</inlineCode>{` prop.`}</p>
        <p>{`This allows you to destructure certain returned props from the state hook, including action methods like `}<inlineCode parentName="p">{`reset`}</inlineCode>{`.`}</p>
        <p>{`An example usecase of this might be programmatically providing the user a way to clear or reset the Combobox of it's previous selections. In the example below we are providing a clear button as an input suffix. When pressed, it uses the `}<inlineCode parentName="p">{`reset`}</inlineCode>{` action method from the hook to clear the input and select item values.`}</p>
        <p>{`It should be noted that when doing so, the `}<inlineCode parentName="p">{`state`}</inlineCode>{` prop takes precident over the `}<a parentName="p" {...{
            "href": "#state-props"
          }}>{`other properties`}</a>{` that affect the state or initial state of the `}<inlineCode parentName="p">{`Combobox`}</inlineCode>{`. They will be ignored in favour of them being provided as arguments to the `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` hook.`}</p>
        <p>{`For full details on how to use the state hook, and what props to provide it, follow the `}<a parentName="p" {...{
            "href": "/primitives/combobox-primitive#usecomboboxprimitive-arguments"
          }}>{`Combobox Primitive documentation`}</a>{`. It's the same hook, just renamed.`}</p>
        <LivePreview scope={{
          Combobox,
          Box,
          Button,
          CloseIcon,
          SearchIcon,
          useCombobox
        }} noInline language="jsx" mdxType="LivePreview">
  {stateHookCombobox}
        </LivePreview>
        <h2>{`States`}</h2>
        <h4>{`Disabled Combobox`}</h4>
        <LivePreview scope={{
          Combobox
        }} noInline language="jsx" mdxType="LivePreview">
  {disabledExample}
        </LivePreview>
        <h4>{`Combobox with inline error`}</h4>
        <LivePreview scope={{
          Combobox
        }} noInline language="jsx" mdxType="LivePreview">
  {errorExample}
        </LivePreview>
        <h2>{`Anatomy`}</h2>
        <Box marginBottom="space60" mdxType="Box">
  <Table tableLayout="fixed" mdxType="Table">
    <THead mdxType="THead">
      <Tr mdxType="Tr">
        <Th mdxType="Th">Property</Th>
        <Th mdxType="Th">Default token</Th>
        <Th mdxType="Th">Modifiable?</Th>
      </Tr>
    </THead>
    <TBody mdxType="TBody">
      <Tr mdxType="Tr">
        <Td mdxType="Td">Label text</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">$font-size-30</ListItem>
            <ListItem mdxType="ListItem">Default: $color-text, $font-weight-semibold</ListItem>
            <ListItem mdxType="ListItem">Disabled: $color-text-weaker, $font-weight-semibold</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Required field indicator</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">4px size (0.25rem)</ListItem>
            <ListItem mdxType="ListItem">Background: $color-background-required</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Box Shadow</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Default: $shadow-border</ListItem>
            <ListItem mdxType="ListItem">Hover: $shadow-border-primary-dark</ListItem>
            <ListItem mdxType="ListItem">Disabled: $shadow-border-light</ListItem>
            <ListItem mdxType="ListItem">Error: $shadow-border-error</ListItem>
            <ListItem mdxType="ListItem">Error hover: $shadow-border-error-dark</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Background</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Default: $color-background-body</ListItem>
            <ListItem mdxType="ListItem">Disabled: $color-background</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Prefix/suffix</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Background: $color-background</ListItem>
            <ListItem mdxType="ListItem">Border: $color-border-lighter</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Prefix/suffix text</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Default: $color-text</ListItem>
            <ListItem mdxType="ListItem">Disabled: $color-text-weak</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Help text</Td>
        <Td mdxType="Td">$color-text-weak, $font-size-30</Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Inline error</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Text: $color-text-error, $font-size-30</ListItem>
            <ListItem mdxType="ListItem">Icon: IconError, $color-text-error, $icon-size-20</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Spacing</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">
              Label:
              <UnorderedList marginBottom="space0" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">Bottom: $space-10</ListItem>
                <ListItem mdxType="ListItem">Between label and required indicator: $space-20</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem mdxType="ListItem">
              Input:
              <UnorderedList marginBottom="space0" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">Left padding: $space-40</ListItem>
                <ListItem mdxType="ListItem">Top, right, bottom padding: $space-30</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem mdxType="ListItem">Prefix/suffix padding: $space-30</ListItem>
            <ListItem mdxType="ListItem">
              Help text:
              <UnorderedList marginBottom="space0" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">Top: $space-20</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem mdxType="ListItem">
              Inline error:
              <UnorderedList marginBottom="space0" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">Spacing between icon and text: $space-20</ListItem>
                <ListItem mdxType="ListItem">Top: $space-20</ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Chevron</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Icon: IconChevronDown, $space-20 from left</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
    </TBody>
  </Table>
        </Box>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/combobox - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Combobox} from '@twilio-paste/combobox';

const Component = () => <Combobox autocomplete items={foo} labelText="Foo" helpText="Bar" />;
`}</code></pre>
        <h4>{`Component Props`}</h4>
        <p>{`All the valid HTML attributes for `}<inlineCode parentName="p">{`input`}</inlineCode>{` are supported including the following props:`}</p>
        <h5><inlineCode parentName="h5">{`autocomplete?: boolean`}</inlineCode></h5>
        <p>{`Allows autocomplete/typehead feature.`}</p>
        <h5><inlineCode parentName="h5">{`groupItemsBy?: string`}</inlineCode></h5>
        <p>{`The name of the key in your item objects that you would like Combobox to group the items by.`}</p>
        <h5><inlineCode parentName="h5">{`groupLabelTemplate?: (groupName: string) => React.ReactNode`}</inlineCode></h5>
        <p>{`This function allows you to use your own `}<inlineCode parentName="p">{`jsx`}</inlineCode>{` template for the group label in the drop-down list.`}</p>
        <h5><inlineCode parentName="h5">{`helpText?: string | React.ReactNode`}</inlineCode></h5>
        <p>{`The contents of the help and error text.`}</p>
        <h5><inlineCode parentName="h5">{`labelText: string | NonNullable<React.ReactNode>`}</inlineCode></h5>
        <p>{`The contents of the label text.`}</p>
        <h5><inlineCode parentName="h5">{`optionTemplate?: (item: string | {}) => React.ReactNode`}</inlineCode></h5>
        <p>{`This function allows you to use your own `}<inlineCode parentName="p">{`jsx`}</inlineCode>{` template for the items in the drop-down list.`}</p>
        <h5><inlineCode parentName="h5">{`variant?: string`}</inlineCode></h5>
        <p>{`The variant of the Combobox. Available variants are `}<inlineCode parentName="p">{`default`}</inlineCode>{` or `}<inlineCode parentName="p">{`inverse`}</inlineCode>{`.`}</p>
        <h4>{`State Props`}</h4>
        <p>{`These props are used when want to create a Controlled Combobox. They control the state of the Combobox.`}</p>
        <h5><inlineCode parentName="h5">{`initialIsOpen?: boolean`}</inlineCode></h5>
        <p>{`Sets whether the Combobox is open on initial render.`}</p>
        <h5><inlineCode parentName="h5">{`initialSelectedItem?: Item`}</inlineCode></h5>
        <p>{`Sets the initial item selected when a Combobox is initialized.`}</p>
        <h5><inlineCode parentName="h5">{`inputValue?: string`}</inlineCode></h5>
        <p>{`Sets the value of the input inside the Combobox.`}</p>
        <h5><inlineCode parentName="h5">{`items: Item[]`}</inlineCode></h5>
        <p>{`Array of items to be displayed in the option list.`}</p>
        <h5><inlineCode parentName="h5">{`itemToString?: (item: Item) => string`}</inlineCode></h5>
        <p>{`If items are stored as an object, used to convert item to a string.`}</p>
        <h5><inlineCode parentName="h5">{`onHighlightedIndexChange?: (changes: Partial<UseComboboxState<Item>>) => void`}</inlineCode></h5>
        <p>{`This function is called each time the highlighted item was changed. Items are highlighted if they are hovered over
or with keyboard actions.`}</p>
        <h5><inlineCode parentName="h5">{`onInputValueChange?: (changes: Partial<UseComboboxState<Item>>) => void`}</inlineCode></h5>
        <p>{`This function is each time the value of the input changes.`}</p>
        <h5><inlineCode parentName="h5">{`onIsOpenChange?: (changes: Partial<UseSelectState<Item>>) => void`}</inlineCode></h5>
        <p>{`This function is each time the value of the input changes.`}</p>
        <h5><inlineCode parentName="h5">{`onSelectedItemChange?: (changes: Partial<UseSelectState<Item>>) => void`}</inlineCode></h5>
        <p>{`This function is called each time the selected item changes. Items are selected by click or the enter key while
highlighted.`}</p>
        <h5><inlineCode parentName="h5">{`selectedItem?: any`}</inlineCode></h5>
        <p>{`Used to set the Selected Item of the Combobox.`}</p>
        <h5><inlineCode parentName="h5">{`state?: Partial<UseComboboxPrimitiveReturnValue<Item>>`}</inlineCode></h5>
        <p>{`Used as a replacement the state props when coupled with using the `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` hook. When using this prop, all other state props are `}<strong parentName="p">{`ignored`}</strong>{`. They must be passed to `}<inlineCode parentName="p">{`useCombobox`}</inlineCode>{` as arguments instead.`}</p>
        <h4>{`useCombobox Arguments`}</h4>
        <p>{`Please see the `}<a parentName="p" {...{
            "href": "/primitives/combobox-primitive#usecomboboxprimitive-arguments"
          }}>{`Combobox Primitive`}</a>{`.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      